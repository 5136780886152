// Convenience file for quickly filling in data during dev/testing

export const contact = {
    firstName: 'PEW',
    lastName: 'pew',
    email: 'pew@pew.pe',
    telephone: '198981981',
    telephoneExtension: '789',
    countryOfResidence: 'Canada [country=CA]',
    provinceState: 'New Brunswick [province=NB]',
    languagePreference: 'French',
};

export const inquiryPersonal = {
    questionTopic: "people",
    topic: "Delayed Shipment [topic=delayedShipment]",
    statusInCanada: "Canadian Citizen [statusInCanada=1]",
    thirdTopic: "arriving By Canada Post [subTopic=arrivingByCanadaPost]",
    detailedDescription: 'some description',
}

export const inquiryCssh = {
    questionTopic: "cssh",
    commercialProgram: 'Canadian importer/exporter [commercialProgram=100000001]',
    iam: 'An authorized representative of the company [iam=888060000]',
    authRepType: 'Accountant [authRepType=888060003]',
    'category-bistier2': 'CARM Client Portal - Accounts and Profiles [category=CAT-01198]',
    selectedSubCategory: 'CCP - Business profile [subCategory=CAT-01209]',
    detailedDescription: 'some description',
    businessName: "Widget co",
    businessNumber: "123",
    rmNumber: "789"
}

export const inquiryCfs = {
    "detailedDescription": "some description",
    "questionTopic": "cfs",
    "commercialProgram": "Canadian importer/exporter [commercialProgram=100000001]",
    "iam": "An employee of the company [iam=888060001]",
    "authRepType": "",
    "category-bistier2": "Financial account activities [category=CAT-01007]",
    "selectedSubCategory": "A transaction is missing [subCategory=CAT-01057]",

    //Business fields
    "dateOfTransaction": "2024-08-09",
    "transactionAmount": "$123.23",
    "statementOfAccountDate": "2024-08-10",
    "statementOfAccountAmount": "$12.22",
    "dailyNoticeDate": "2024-08-11",
    "dailyNoticeAmount": "$33.33",
    "previousTicketNumber": "123456789",
    "businessName": "123",
    "businessNumber": "123",
    "rmNumber": "234",
    "transactionNumber": "234234"
}

export const inquiryTccu = {
    "category-bistier2": "EDI & API Support [category=CAT-01005]",
    "authRepType": "Accountant [authRepType=888060003]",
    "businessName": "Widget co",
    // "businessNumber": "111",
    // "cargoControlNumber": "444",
    "commercialProgram": "Canadian importer/exporter [commercialProgram=100000001]",
    // "conveyanceReferenceNumber": "333",
    "detailedDescription": "some description",
    "iam": "An authorized representative of the company [iam=888060000]",
    "modeOfTransport": "Highway [modeOfTransport=888060002]",
    "questionTopic": "tccu",
    // "rmNumber": "222",
    "selectedSubCategory": 'Help with an RNS EDI transmission [subCategory=CAT-01036]',
    "telephone": "198981981",
    "telephoneExtension": "789",
    "transactionNumber": "555"
}

export const inquiryBistier = {
    "detailedDescription": "some description",
    "questionTopic": "bistier2",
    "commercialProgram": "Canadian importer/exporter [commercialProgram=100000001]",
    "iam": "An authorized representative of the company [iam=888060000]",
    "authRepType": "Trustee in bankruptcy [authRepType=888060004]",
    "category-bistier2": "Exporting commercial goods [category=CAT-01006]",
    "selectedSubCategory": "General Guidelines for exporting goods [subCategory=CAT-01040]",
    "group": "BIS"
}