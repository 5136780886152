import React from 'react';
import {FormattedMessage} from 'react-intl';

function CIPSForm({navigation}) {

	return (
		<div>
				<div className="pull-right col-md-4">
					<section className="panel panel-primary mrgn-bttm-sm">
						<div className="panel-heading">
							<h2 className="panel-title">
								<FormattedMessage id="learn-more" />
							</h2>
						</div>
						<ul className="list-group">
							<li className="list-group-item">
									<FormattedMessage id="serving-our-clients" 
									values={{
										a: (chunks) => <a className="alert-link" href={"https://cbsa-ut.isvcs.net/publications/pub/bsf5124-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"}>{chunks}</a>
									}} 
									/>
							</li>
						</ul>
					</section>

					<section className="panel panel-success mt-3">
						<header className="panel-heading">
							<FormattedMessage id="processingTime" />
						</header>
						<div className="panel-body">
							<div className="pull-left mrgn-rght-md text-center panel panel-success mrgn-bttm-0">
								<div className="panel-body">
									<span className="fa fa-calendar fa-3x text-success"></span>
									<span className="h2 text-success">
										21<span className="wb-inv"> working days</span>
									</span>
								</div>
							</div>
							<FormattedMessage
								id="processingNote"
								values={{
									a: (chunks) => <a className="alert-link" href={"https://cbsa-ut.isvcs.net/services/serving-servir/processing-traitement-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html#_s2"}>{chunks}</a>
								}} 
							/>
							<p><small><FormattedMessage id="processingUpdateDate"/></small></p>
						</div>
					</section>
			</div>
			<div className="col-md-8">
				<p><FormattedMessage id="experience-understand-compliments" />
				</p>
				<div className="alert alert-info">
					<p><b><FormattedMessage id="note" /></b><br/>
					<FormattedMessage 
						id="sensitive-complaint-compliment" 
						values={{
							b: (chunks) => <b>{chunks}</b>, 
							abbr: (chunks) => <abbr>{chunks}</abbr>, 
							a: (chunks) => <a className="alert-link" href={"https://www.cbsa-asfc.gc.ca/contact/bis-sif-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"}>{chunks}</a>
						}} 
					/>
					</p>
				</div>
				<div>
					<h2>
						<FormattedMessage id="how-to-submit-your-feedback" />
					</h2>
					<p><FormattedMessage id="compliment-completing-electronic" />
					</p>
				</div>
			</div>
			<div className="col-md-12">
				<hr />
				<h2>
					<i className="fa fa-desktop">
					</i>
					<FormattedMessage id="online" />
				</h2>
				<h4>
					<FormattedMessage id="compliment" />
				</h4>
				<p><FormattedMessage id="performance-responsible-recognition" />
				</p>
				{/* TODO: Refactor with constants */}
				<button type='button' name='compliment' id='compliment' value='compliment' className='btn btn-primary' onClick={() => navigation.navigate("CIPSSubmissionForm", {type: 'compliment'})}> 
					<FormattedMessage id="submit-a-compliment" />
				</button>
				<h4>
					<FormattedMessage id="complaint" />
				</h4>
				<div className="alert alert-warning">
					<p><b><FormattedMessage id="redirected-appropriate-erroneously-header" /></b><br/>
					<FormattedMessage 
						id="redirected-appropriate-erroneously" 
						values={{b: (chunks) => <b>{chunks}</b>, strong: (chunks) => <strong>{chunks}</strong>, a: (chunks) => <a href={"https://www.cbsa-asfc.gc.ca/contact/faq-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"}>{chunks}</a>}} />
					
					</p>
				</div>
				<p><FormattedMessage id="performance-expectation-professional" />
				</p>
				<button type='button' name='complaint' id='complaint' value='complaint' className='btn btn-primary' onClick={() => navigation.navigate("CIPSSubmissionForm", {type: 'complaint'})}>
					<FormattedMessage id="submit-a-complaint" />
				</button>
				<hr />
				<h2>
					<i className="fa fa-envelope-o">
					</i>
					<FormattedMessage id="by-mail" />
				</h2>
				<br />
				<p><FormattedMessage id="compliment-submitting-submissions-feedback" />
				</p>
				<br />
				<p className="mrgn-lft-lg"><FormattedMessage id="feedback" /><br /><br /><FormattedMessage id="recourse-directorate" /><br /><FormattedMessage id="border-canada-services" /><br /><FormattedMessage id="mail-postal" />
				</p>
				<br />
				<hr />
				<h2>
					<FormattedMessage id="communicating-with-our-staff" />
				</h2>
				<p><FormattedMessage id="communicating-discriminatory-communications" />
				</p>
				<h2>
					<FormattedMessage id="processing-times-and-service-standards" />
				</h2>
				<p><FormattedMessage id="processing-complexity-comprehensive" />
				</p>
				<br />
				<hr />
				<details className="alert alert-success">
					<summary>
						<h2 className="alert-link">
							<FormattedMessage id="service-standards" />
						</h2>
					</summary>
					<div className="col-md-11">
						<p className="h2">
							<FormattedMessage id="nowrap-fiscal-datetime" />
						</p>
						<h3>
							<FormattedMessage id="feedback-mechanism-complaints" />
						</h3>
						<h4>
							<FormattedMessage id="feedback-mechanism-complaints-initial-contact" />
						</h4>
						<p><b><FormattedMessage id="service-description" /></b>: <FormattedMessage id="purpose-response-complaint" />
						</p>
						<p><b><FormattedMessage id="service-standard" /></b>: <FormattedMessage id="calendar-received-complaint" values={{abbr: (chunks) => <abbr>{chunks}</abbr>}} />
						</p>
						<div className="mrgn-lft-lg mrgn-bttm-lg">
							<p><b><FormattedMessage id="performance-target" /></b>: 85%
							</p>
							<p><b><FormattedMessage id="performance-result" /></b>: <FormattedMessage id="service-standard-contacted-2" values={{abbr: (chunks) => <abbr>{chunks}</abbr>}} />
							</p>
						</div>
						<h4>
							<FormattedMessage id="feedback-mechanism-complaints-final-response" />
						</h4>
						<p>
							<b><FormattedMessage id="service-description" /></b>: <FormattedMessage id="response-provides-complaint" values={{abbr: (chunks) => <abbr>{chunks}</abbr>}} />
						</p>
						<p>
							<b><FormattedMessage id="service-standard" /></b>: <FormattedMessage id="received-response-complaint" values={{abbr: (chunks) => <abbr>{chunks}</abbr>}} />
						</p>
						<div className="mrgn-lft-lg mrgn-bttm-lg">
							<p><b><FormattedMessage id="performance-target" /></b>: 90%
							</p>
							<p><b><FormattedMessage id="performance-result" /></b>: <FormattedMessage id="service-standard-responded-2" values={{abbr: (chunks) => <abbr>{chunks}</abbr>}} />
							</p>
						</div>
						<div className="clearfix">
						</div>
						<div className="alert alert-info">
							<p><FormattedMessage id="standards-information-performance" values={{a: (chunks) => <a href={"https://www.cbsa-asfc.gc.ca/services/serving-servir/standards-normes-"+(document.documentElement.lang === "fr" ? "fra" : "eng")+".html"}>{chunks}</a>, abbr: (chunks) => <abbr>{chunks}</abbr>}} />
							</p>
						</div>
					</div>
					<div className="clearfix">
					</div>
				</details>
			</div>
		</div>
	);
}

// CIPSForm.path = "cipsform";
CIPSForm.path = "";
CIPSForm.navigationOptions = {
	pageTitle: <FormattedMessage id="feedback" />
	, breadcrumb: [
		<a href={"https://www.cbsa-asfc.gc.ca/menu-"+document.documentElement.longLang+".html"}><FormattedMessage id="breadcrumbs.cbsaHome"/></a>,
	]
	, breadcrumbTitle: <FormattedMessage id="feedback" />
};

export default CIPSForm;